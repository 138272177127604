dialog {
  padding: 0;
  border: 0;
  border-radius: 0.6rem;
  box-shadow: 0 0 2em $black;
  overflow: hidden;
  background: none;
}

/* native backdrop */
dialog::backdrop {
  background-color: rgba(0, 0, 0, 0.75);
}

/* polyfill backdrop */
dialog + .backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.75);
}

dialog[open] {
  animation: slide-up 0.4s cubic-bezier(0.345, 0, 0.25, 1) 0s;
}

/* The following styles are for older browsers when using
   the polyfill. These aren’t necessary for Chrome/Firefox. */
dialog {
  display: none;
  position: absolute;
  margin: 0 auto; /* should center it, but not working in Safari */
  width: 80vw;
  max-width: 960px;
}

dialog[open] {
  display: block;
}

/* prettying things up a bit */
.modal__close-button {
  position: absolute;
  top: 0;
  right: 1.8rem;
  line-height: 1;

  background-color: transparent;
  border: 0;

  > span {
    display: inline-block;

    &:nth-child(1) {
      top: -4px;
      margin-right: 0.5rem;
    }

    &:nth-child(2) {
      font-size: rem(32px);
    }
  }

  &:focus {
    outline: -webkit-focus-ring-color auto 0;
  }
}

.modal__header,
.modal__body,
.modal__footer {
  padding: 1em;
}

.modal__body {
  background: $green;
}

.modal__header {
  margin: 0;
  padding-bottom: 1.2em;
  background: $black;
  border-top-left-radius: 0.6rem;
  border-top-right-radius: 0.6rem;
  display: block;
  color: $white;
  box-shadow: 0 5px 7px -2px rgba(51, 51, 51, 0.44);

  .modal__logo {
    color: #fff;
    height: 22px;
    width: 145px;
    background-image: url('/dist/images/png/volt-z-logo-white.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.modal__footer {
  background: $black;
  color: $white;
  box-shadow: 0 -5px 7px -2px rgba(51, 51, 51, 0.44);

  .modal__footer__wrapper {
    .modal__footer--inner {
      display: table;
      width: 100%;
      vertical-align: middle;

      p {
        width: 50%;
        display: table-cell;
        vertical-align: middle;
        color: $white;

        &:nth-last-child(1) {
          text-align: right;
        }

        @media #{$small-only} {
          font-size: rem(14px);
          vertical-align: top;
        }
      }
    }
  }
}

/* misc page styles -- not important for demo */

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translate(0, 1.5vh);
  }

  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}
