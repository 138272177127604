// BEGIN NAV

$toggle-size: 32px;
$bar-size: $toggle-size / 8;
$bar-space: ($toggle-size - ($bar-size * 5)) / 2;

$bar2-top: $bar-size + $bar-space;
$bar3-top: $bar2-top * 2;

#nav-container {
  margin-left: auto;
  position: relative;
}

#nav-mobile__button {
  display: block;
  width: $toggle-size;
  height: $toggle-size;

  cursor: pointer;

  transform: rotate(0deg);
}

#nav-mobile__button span {
  background-color: $white;
  width: 100%;
  height: $bar-size;

  border-radius: $bar-size / 4;

  display: block;
  position: absolute;
  left: 0;
  content: '';

  // transition time and time function
  transition: all 330ms cubic-bezier(0.345, 0, 0.25, 1) 0s;
}

#nav-mobile__button span:nth-child(1) {
  top: $bar-size;
  transform-origin: left center;
}

#nav-mobile__button span:nth-child(2) {
  top: $bar2-top + $bar-size;
  transform-origin: left center;
}

#nav-mobile__button span:nth-child(3) {
  top: $bar3-top + $bar-size;
  transform-origin: left center;
}

.is-open #nav-mobile__button span:nth-child(1) {
  transform: rotate(45deg);
  top: -($toggle-size / 32) + $bar-size;
  left: $bar-size;
}

.is-open #nav-mobile__button span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

.is-open #nav-mobile__button span:nth-child(3) {
  transform: rotate(-45deg);
  top: $bar3-top + ($toggle-size / 32) + $bar-size;
  left: $bar-size;
}
