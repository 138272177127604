.form {
  width: 100%;

  .form__header {
    margin-bottom: 0;
  }

  .form__body {
    margin-bottom: 0;
  }

  .form__container {
    @media #{$medium-up} {
      .form__row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .form__item {
          width: 100%;
        }
        .form__item:nth-of-type(2) {
          margin: 0 1rem;
        }
      }
    }
  }

  .form__items {
    position: relative;
    width: 100%;
    margin-top: 40px;

    .form__type-label {
      color: $black;
      font-size: rem(16px);
      font-weight: normal;
      opacity: 1;
      padding: 0 0 0 8px;
      position: absolute;
      pointer-events: none;
      left: 0px;
      top: 19px;
      transition: all 0.44s;
      transition-timing-function: cubic-bezier(0.56, 1.19, 0.2, 1.05);

      > sup {
        font-size: rem(16px);
        top: -0.2em;
        font-weight: 700;
        color: $black;
      }
    }

    .form__type-input,
    .form__type-textarea {
      display: block;
      width: 100%;
      height: 60px;
      padding: 16px 8px;
      border: 0;
      border-radius: 0;
      font-size: rem(16px);
      font-weight: 400;
      line-height: 1;
      background: rgba(255, 255, 255, 0.97);
      color: $black;
      outline: 0;
      resize: none;
    }

    .form__type-input:focus,
    .form__type-textarea:focus {
      outline: none;
      border-bottom: 2px solid $black;
    }

    .form__type-input:focus ~ .form__type-label,
    .form__type-input:not(:placeholder-shown) ~ .form__type-label,
    .form__type-textarea:focus ~ .form__type-label,
    .form__type-textarea:not(:placeholder-shown) ~ .form__type-label {
      top: -28px;
      color: $black;
    }

    .form__type-textarea {
      min-height: 90px;
      line-height: 1;
      padding: 16px 8px;
    }

    .form__item-label--radio {
      height: 60px;
      line-height: 60px;

      .form__radio {
        line-height: 60px;
      }
    }

    .form__type-button {
      padding: 0 15px;
      color: $white;
      border: 1px solid $black;
      border-radius: 2px;
      background: $black;
      height: 60px;
      line-height: 60px;

      > span {
        font-weight: 700;
        text-transform: uppercase;
      }

      @media #{$small-only} {
        display: block;
        width: 100%;
      }
    }

    &.form__item-right {
      text-align: right;
    }
  }

  &.error {
    .form__type-input {
      border: 1px solid $black;
    }
  }
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  cursor: pointer;
  &:focus {
    outline: -webkit-focus-ring-color auto 0;
  }
}

[data-type='validator-error'] {
  font-size: 11px;
  position: absolute;
  z-index: 10;
  margin-top: 2px;
  color: #ffffff;
  background: rgb(200, 53, 53);

  padding: 4px 10px;
  border-radius: 1px;
  pointer-events: none;
}

[data-type='validator-error']:before {
  content: '';
  width: 0;
  height: 0;
  top: -5px;
  position: absolute;
  left: 6px;
  border-left: 5px solid rgba(0, 0, 0, 0);
  border-right: 5px solid rgba(0, 0, 0, 0);
  border-bottom: 5px solid #c83535;
}
