header {
  position: relative;
  color: $white;

  .navigation__container {
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.33);

    .navigation__wrapper {
      width: 100vw;
      max-width: 1440px;
      height: 58px;
      margin: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;

      z-index: 2;

      @media #{$small-only} {
        padding: 0 5.5vw;
      }

      @media #{$medium-only} {
        padding: 0 5.5vw;
      }
      @media #{$large-up} {
        padding: 0 2.5vw;
      }
    }

    .navigation__logo {
      height: 28px;
      width: 145px;

      .green {
        fill: $green;
      }

      .white {
        fill: $white;
      }

      @media #{$small-only} {
        width: 96px;
      }

      @media #{$small-only} {
        left: 20px;
      }
    }

    #open-modal {
      flex-grow: 3;
      text-align: right;
      margin: 0 2.5vw 0 0;

      @media #{$small-only} {
        right: 80px;
      }
      > span {
        line-height: 37px;
        display: inline-block;
      }
    }

    .navigation__transitions {
      position: fixed;
      left: 0;
      top: 0;
      background: linear-gradient(135deg, $toBlack 0%, $fromBlack 100%);
      z-index: 1;
      width: 0.33%;
      height: 58px;
      transition: width 330ms;
      transition-timing-function: cubic-bezier(0.345, 0, 0.25, 1) 0s;

      &.is-open {
        width: 100vw;
      }
    }
  }

  .navigation__circletransition::before {
    content: '';
    will-change: scale;
    position: fixed;
    top: -75px;
    right: -75px;
    z-index: -1;
    width: 75px;
    height: 75px;
    background: linear-gradient(135deg, $fromBlack 0%, $toBlack 100%);
    border-radius: 100%;
    transform-origin: center 70%;
    transition: all 0.44s;
    transition-timing-function: cubic-bezier(0.56, 1.19, 0.2, 1.05);
  }

  nav {
    @include absoluteCentered;

    margin: 0 auto;
    text-align: center;

    .mainmenu__list {
      margin: 0;
      padding: 0;

      li {
        opacity: 0;
        transition: opacity 0.35s;
        perspective: 1000;
        backface-visibility: hidden;
        visibility: hidden;
        list-style: none;
        text-transform: uppercase;
        letter-spacing: 5px;
        transform: translate3d(0, 0, 0);
        transition-timing-function: cubic-bezier(0.56, 1.19, 0.2, 1.05);

        &:nth-child(1) {
          transition-delay: 0.1s;
        }

        &:nth-child(2) {
          transition-delay: 0.2s;
        }

        &:nth-child(3) {
          transition-delay: 0.3s;
        }

        &:nth-child(4) {
          transition-delay: 0.4s;
        }

        &:nth-child(5) {
          transition-delay: 0.5s;
        }

        &:nth-child(6) {
          transition-delay: 0.6s;
        }

        &:nth-child(7) {
          transition-delay: 0.7s;
        }

        &:nth-child(8) {
          transition-delay: 0.8s;
        }

        &:nth-child(9) {
          transition-delay: 0.9s;
        }

        a {
          display: inline-block;

          color: $white;
          text-transform: uppercase;
          white-space: nowrap;
          padding: 0.5vh 0.5vw;
          border: 0;

          @media #{$small-only} {
            font-size: rem(16px);
          }

          @media #{$medium-only} {
            font-size: rem(28px);
          }

          @media #{$large-up} {
            font-size: rem(32px);
          }

          &.is-active {
            transition: border-color 1000ms;
            margin: 0.3em 0;
            padding: 0.3em 0;
            border: solid 1px $white;
            cursor: default;
            transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
          }

          span {
            position: relative;
            display: inline-block;
            vertical-align: middle;
            zoom: 1;
            color: $white;
            font-size: inherit;
            padding: 0.5vh 0.5vw;
            transition: color 0.33s;
            transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);

            &:hover {
              color: $green;
            }

            &::after {
              display: block;
              content: '';
              border-bottom: solid 2px $green;
              transform: scaleX(0);
              transition: transform 0.33s;
              transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
            }

            &:hover::after {
              transform: scaleX(1);
            }
          }
        }

        .mainmenu__item--svg {
          width: 72px;
          height: 72px;
          padding: 0.5vh 0.5vw;
          margin: auto;

          .green {
            fill: $green;
          }
        }
      }
    }
    @media #{$small-only} {
      top: 45%;
    }
  }

  &.is-open {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 90;

    .navigation__circletransition::before {
      transform: scale(100);
      transition: all 0.44s;
      transition-timing-function: cubic-bezier(0.56, 1.19, 0.2, 1.05);
    }

    .mainmenu__list li {
      opacity: 1;
      visibility: visible;
      transition: opacity 0.7s;
      transition-timing-function: cubic-bezier(0.56, 1.19, 0.2, 1.05);

      &:nth-child(1) {
        transition-delay: 0.9s;
      }

      &:nth-child(2) {
        transition-delay: 0.8s;
      }

      &:nth-child(3) {
        transition-delay: 0.7s;
      }

      &:nth-child(4) {
        transition-delay: 0.6s;
      }

      &:nth-child(5) {
        transition-delay: 0.5s;
      }

      &:nth-child(6) {
        transition-delay: 0.4s;
      }

      &:nth-child(7) {
        transition-delay: 0.3s;
      }

      &:nth-child(8) {
        transition-delay: 0.2s;
      }

      &:nth-child(9) {
        transition-delay: 0.1s;
      }
    }
  }
}
