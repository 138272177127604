/*------------------------------------*\
    # Simple Typography Settings
\*------------------------------------*/

/**@include fontface('BariolRegular','/dist/fonts/BariolRegular','BariolRegular');**/
/**@include fontface('Nunito', '/dist/fonts/Nunito', 'Nunito-Regular');**/
@include fontface('Quicksand', '/dist/fonts/Quicksand', 'Quicksand-Regular');
/**@include fontface('Raleway', '/dist/fonts/Raleway', 'Raleway-Regular');**/

// Font Family
$font-family: 'Quicksand';

// Base Font for HTML
$base-font-size: 16px;
$base-font-weight: normal;
$base-line-height: 1.44;

// Paragraph Styles
$paragraph-color: $toBlack;
$paragraph-size: rem(24px);
$paragraph-line-height: $base-line-height;
$paragraph-margin: rem(20px);
$paragraph-margin-top: 0;

// Header Styles

$header-size: rem(36px);
$header-line-height: $base-line-height;
$header-margin: $paragraph-margin * 1.2;

$link-color: $toBlack;

/*------------------------------------*\
    # Type
\*------------------------------------*/

// Make type normal across browsers
*,
html,
body {
  font-size: $base-font-size;
  font-family: $font-family;
  font-weight: $base-font-weight;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  color: $black;
  line-height: $base-line-height;
}

// Headers
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: $header-line-height;
  position: relative;
  text-transform: uppercase;
  display: inline-block;
  margin-top: 0;
  color: inherit;
  font-weight: 700;
}

h1 {
  @media #{$small-only} {
    font-size: $header-size / 1.95;
    margin-bottom: $header-margin * 0.62125;
  }

  @media #{$medium-only} {
    font-size: $header-size / 1.225;
    margin-bottom: $header-margin * 1.25;
  }

  @media #{$large-up} {
    font-size: $header-size;
    margin-bottom: $header-margin * 1.75;
  }
}

h2 {
  font-size: $header-size / 1.3;
  margin-bottom: $header-margin / 1.3;
}

h3 {
  font-size: $header-size / 1.5;
  margin-bottom: $header-margin / 1.5;
}

h4 {
  font-size: $header-size / 2;

  @media #{$small-only} {
    font-size: $header-size / 2.5;
    margin-bottom: $header-margin * 0.5;
  }

  @media #{$medium-only} {
    font-size: $header-size / 1.7;
    margin-bottom: $header-margin * 0.75;
  }

  @media #{$large-up} {
    margin-bottom: $header-margin;
  }
}

h5 {
  font-size: $header-size / 1.8;
  margin-bottom: $header-margin / 1.8;
}

h6 {
  font-size: $header-size / 2;
  margin-bottom: $header-margin / 2;
}

// Paragraphs
p {
  margin-top: $paragraph-margin-top;
  margin-bottom: $paragraph-margin;
  font-size: $paragraph-size;
  line-height: $paragraph-line-height;
  color: $paragraph-color;

  &:last-of-type {
    margin-bottom: 0;
  }
  @media #{$small-only} {
    font-size: $paragraph-size / 1.5;
    margin-bottom: $paragraph-margin / 1.7;
  }
}

// Links
a {
  color: $link-color;
  transition: all 0.2s ease;

  &:hover {
    color: darken($link-color, 20%);
  }
}

.text-left {
  text-align: left;
}

.text-centered {
  text-align: center;
}

.text-right {
  text-align: right;
}

h1.services__title--vertical,
h1.background__title--vertical,
h1.profile__title--vertical {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  height: 0;

  &::before {
    content: '';
    display: inline-block;
    height: 28px;
    width: 4px;
    background-color: $green;
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
    position: absolute;
    top: 6px;
    left: -2.5vh;

    @media #{$small-only} {
      height: 16px;
    }
    @media #{$medium-only} {
      height: 22px;
      top: 8px;
    }
  }
}

h1.advice__title--horizontal,
h1.projects__title--horizontal,
h1.management__title--horizontal,
h1.clients__title--horizontal {
  &::before {
    content: '';
    display: inline-block;

    background-color: $green;
    position: absolute;

    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
    width: 4px;

    @media #{$small-only} {
      top: 6px;
      height: 14px;

      left: -3vw;
    }

    @media #{$medium-only} {
      top: 8px;
      height: 22px;

      left: -2.5vw;
    }

    @media #{$large-up} {
      height: 28px;
      top: 9px;
      left: -2vw;
    }
  }
}
