@mixin fontface($font-family, $font-url, $font-name) {
  @font-face {
    font: {
      family: $font-family;
      style: normal;
      weight: normal;
    }
    src: url($font-url+'/'+$font-name+'.eot');
    src: url($font-url+'/'+$font-name+'.eot#iefix') format('embedded-opentype'),
      url($font-url+'/'+$font-name+'.woff') format('woff'),
      url($font-url+'/'+$font-name+'.ttf') format('truetype'),
      url($font-url+'/'+$font-name+'.svg#'+$font-name) format('svg');
  }
}

@mixin absoluteCentered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin absoluteCentered-top {
  position: absolute;
  top: 3vh;
  left: 50%;
  transform: translate(-50%, 3vh);
}

@mixin absoluteCentered-bottom {
  position: absolute;
  top: 95%;
  left: 50%;
  transform: translate(-50%, -95%);
}

@mixin absoluteCentered-left {
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(-50%, 0%);
}

@mixin absoluteCentered-right {
  position: absolute;
  top: 50%;
  right: 3.33334%;
  transform: translate(-50%, -3.33334%);
}

@mixin relativeCenteredTop {
  position: relative;
}

@mixin aspect-ratio($width, $height) {
  position: relative;

  &::before {
    display: block;
    content: ' ';
    width: 100%;
    padding-top: $height / $width * 100%;
  }

  > .ratio-content,
  .advice__ratio-content,
  .clients__ratio-content,
  .profile__ratio-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 3;
  }
}

// PX to REM - Usage: font-size: rem(37px);
@function rem($px, $base: $base-font-size) {
  @return ($px / $base) * 1rem;
}
