.scroll__button--fixed {
  position: fixed;
  right: 0;
  z-index: 9;
  opacity: 0;
  transform: translateX(100%) scale(0);
  transition: opacity 0.33s, transform 0.33s;

  @media #{$small-only} {
    bottom: 23vh;
    top: auto;
  }

  @media #{$medium-only} {
    bottom: 24vh;
    top: auto;
  }
  @media #{$large-up} {
    bottom: 28vh;
    top: auto;
  }

  .scroll__button--to-top {
    overflow: hidden;

    .button__icon {
      margin-right: 1rem;
      display: inline-block;
      vertical-align: middle;
      transition: transform 0s 0.4s;
    }
  }

  .button {
    border-radius: 8px;
    background: linear-gradient(135deg, $toBlack 0%, $fromBlack 100%);
    cursor: pointer;
    user-select: none;
    height: auto;
    line-height: 1;
    border: 0;
    transition: background-color 0.33s;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 7px 7px 17px rgba(0, 0, 0, 0.15);

    p {
      margin: 0;

      font-weight: 700;

      color: $green;
      height: auto;

      @media #{$small-only} {
        font-size: rem(16px);
        padding: 0.5rem 0.75rem 0.5rem 0.75rem;
      }
      @media #{$medium-up} {
        padding: 0.5rem 1rem 0.5rem 1rem;
        font-size: rem(20px);
      }
    }
  }

  &.is-open {
    opacity: 1;
    transform: translateX(0) scale(1);
  }
}
