.advice {
  .advice__figure {
    .verdeel-st3,
    .verdeel-st4 {
      stroke: #000;
      stroke-width: 4;
      stroke-miterlimit: 10;
    }

    .verdeel-st3 {
      stroke-linecap: round;
      fill: #fff;
    }

    .verdeel-st4 {
      fill: none;
    }

    .verdeel-st5 {
      stroke-linecap: round;
    }

    .verdeel-st5,
    .verdeel-st6,
    .verdeel-st7 {
      fill: none;
      stroke: #000;
      stroke-width: 2;
      stroke-miterlimit: 10;
    }

    .verdeel-st7 {
      fill: #fffdfd;
      stroke-width: 4;
      stroke-linecap: round;
    }
    .nood-st1,
    .nood-st2 {
      stroke: #000;
    }

    .nood-st1 {
      stroke-linecap: round;
      stroke-miterlimit: 10;
      fill: #fff;
      stroke-width: 8;
    }

    .nood-st2 {
      fill: none;
      stroke-width: 4;
    }

    .nood-st2,
    .nood-st3,
    .nood-st4 {
      stroke-linecap: round;
      stroke-miterlimit: 10;
    }

    .nood-st3 {
      fill: none;
      stroke: #000;
      stroke-width: 4.021;
    }

    .nood-st4 {
      stroke: #fff;
      stroke-width: 8;
    }
    .data-st1,
    .data-st2 {
      fill: #fff;
      stroke: #000;
      stroke-miterlimit: 10;
    }

    .data-st1 {
      stroke-linecap: round;
      stroke-width: 8;
    }

    .data-st2 {
      stroke-width: 4;
    }

    .data-st3 {
      stroke-linecap: round;
    }

    .data-st3,
    .data-st4 {
      fill: none;
      stroke: #000;
      stroke-width: 4;
      stroke-miterlimit: 10;
    }
    .bev-st2,
    .bev-st7 {
      stroke-width: 8;
      stroke-linecap: round;
      stroke-miterlimit: 10;
    }

    .bev-st2 {
      fill: #fff;
      stroke: #000;
    }

    .bev-st7 {
      stroke: #fff;
    }
    .onderhoud-st1 {
      fill: #fff;
    }
    .instepct-st1 {
      fill: #fff;
      stroke: #000;
      stroke-width: 8;
      stroke-miterlimit: 10;
    }
  }
}
