$backgroundcolor: rgba(239, 239, 239, 1) !default;
$backgroundcolorActive: rgba(239, 239, 239, 1) !default;
$white: rgba(255, 255, 255, 1) !default;
$whiteActive: rgba(255, 255, 255, 1) !default;
$green: rgba(112, 206, 98, 1) !default;
$greenActive: rgba(112, 206, 98, 1) !default;
$greenTransparent: rgba(112, 206, 98, 0.44) !default;
$black: rgba(51, 51, 51, 1) !default;
$blackActive: rgba(51, 51, 51, 1) !default;
$blackTransparent: rgba(51, 51, 51, 0.77) !default;
$fromBlack: rgba(70, 70, 75, 1) !default;
$toBlack: rgba(33, 33, 37, 1) !default;
$fromGreen: rgba(137, 208, 122, 1) !default;
$toGreen: rgba(112, 206, 98, 1) !default;
$blackBoxshadow: rgba(51, 51, 51, 0.44) !default;
$offWhite: rgba(238, 239, 240, 1) !default;
$isgreen: rgba(112, 206, 98, 1) !default;
$profile: rgba(0, 0, 0, 1) !default;
$serviceStart: rgba(248, 248, 248, 1) !default;
$serviceEnd: rgba(239, 239, 239, 1) !default;
$clientsStart: rgba(248, 248, 248, 1) !default;
$clientsEnd: rgba(239, 239, 239, 1) !default;
$backgroundStart: rgba(70, 70, 75, 1) !default;
$backgroundEnd: rgba(33, 33, 37, 1) !default;
$lightGray: rgba(239, 239, 239, 1) !default;

$advice__image-animation: rgba(112, 206, 98, 0.66) !default;
$management__image-animation: rgba(112, 206, 98, 0.66) !default;
$management__ratio-animation: rgba(112, 206, 98, 1) !default;

$profile__image: rgba(51, 51, 51, 0.44) !default;

.white,
.is--white {
  background-color: $white;
}

.copy--is--white {
  color: $white;
}

.green,
.is--green {
  background-color: $green;
}

.copy--is--green {
  color: $green;
}

.black {
  background-color: $black;
  color: $white;
}
