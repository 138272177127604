body,
html {
  height: 100vh;
  width: 100vw;
  position: relative;
  margin: 0;
  padding: 0;

  &.is-open {
    overflow: hidden;
    position: fixed;
  }
}

html {
  background-color: $backgroundcolor;
}

a {
  cursor: pointer;
  text-decoration: none;
  border-bottom: solid 1px $white;
  color: inherit;
  font-size: inherit;

  &:hover {
    color: $white;
  }
}

img {
  display: block;
}

button {
  color: $white;
  font-size: inherit;
  background-color: transparent;
  border: none;

  > span {
    font-size: rem(22px);
    text-transform: uppercase;
    color: $white;

    &:hover {
      color: $green;
    }
  }

  &:focus {
    outline: -webkit-focus-ring-color auto 0;
  }
}

.svg-definitions {
  display: block;
  overflow: hidden;
  width: 0;
  height: 0;
}

svg:not(:root),
symbol {
  overflow: hidden;
}

main > .container {
  overflow-x: hidden;
  position: relative;

  /**scroll-behavior: smooth;**/
}

section {
  display: table;
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
  position: relative;
  padding: 50px 0;

  .content__wrapper {
    width: 100%;
    display: table-cell;
    vertical-align: middle;
  }
}

#services-anchor-name,
#advice-anchor-name,
#projects-anchor-name,
#management-anchor-name,
#impressions-anchor-name,
#profile-anchor-name,
#clients-anchor-name {
  position: absolute;
  left: 0;
  top: -58px;
  z-index: 300;
  display: block;
  width: 100vw;
}

#advice-anchor-name,
#management-anchor-name,
#impressions-anchor-name {
  top: -8px;
}

/* SECTION INTRO */

.intro {
  background-color: $green;
  overflow: hidden;
  padding: 0;

  .draw__a__line {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 50vw;

    .intro__line--black,
    .intro__line--green {
      fill: none;
      stroke-width: 4;
      stroke-miterlimit: 10;
    }

    .intro__line--black {
      stroke: $black;
    }

    .intro__line--green {
      stroke: $green;
    }
  }

  .intro__wrapper {
    width: 100vw;
    position: relative;

    @media #{$small-only} {
      vertical-align: top;
    }

    .intro__inner-mask-lg {
      background-color: $green;
      position: absolute;
      left: 0;
      top: 0;
      height: calc(100vh - 158px);
      width: calc(50vw + 150px);
      z-index: 1;

      @media #{$small-only} {
        width: calc(50vw + 70px);
        height: calc(100vh - 20vh);
      }
    }

    .intro__inner-mask-sml {
      background-color: $green;
      position: absolute;
      left: 0;
      bottom: 0;
      height: 100vh;
      width: calc(50vw + 6px);
      z-index: 1;
    }

    .intro__base {
      margin: auto;
      width: 100vw;
      max-width: 1440px;
      display: flex;
      justify-content: flex-start;
      height: 100vh;
      position: relative;

      @media #{$medium-only} {
        align-items: center;
      }

      @media #{$large-up} {
        align-items: center;
      }
    }

    .intro__item {
      width: calc(1440px / 2);
      position: relative;
      z-index: 3;

      @media #{$small-only} {
        width: 100vw;
      }

      @media #{$medium-only} {
        width: calc(1440px / 3);
      }

      .intro__itemwrapper {
        @media #{$small-only} {
          padding: 0 0 0 5.5vw;
          position: absolute;
          top: 12vh;
          width: 70vw;
        }

        @media #{$medium-only} {
          padding: 0 50px 100px 5.5vw;
        }

        @media #{$large-up} {
          padding: 0 100px 100px 2.5vw;
        }

        .intro__bodywrapper {
          .intro__main,
          .intro__footer {
            .intro__copy {
              p {
                margin: 0;
                padding: 0;

                @media #{$small-only} {
                  padding: 0 5vw 0 0;
                }
              }
            }
          }
        }
      }
    }
  }

  #intro__svg__clippath {
    display: block;
    min-height: 100vh;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 50%;
    z-index: 0;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .intro__clip__overlay {
    background-color: $green;
    display: block;
    height: 110%;
    width: 33.5%;
    position: absolute;
    top: -35px;
    right: -150px;
    opacity: .7;
    transform: rotate(-3deg);
    z-index: 1;

    @media #{$small-only} {
      right: -100px;
    }
  }
}

/* SECTION INTRO */

/* SECTION SERVICES */

.services {
  background-color: $white;

  .draw__a__line {
    position: absolute;
    z-index: 2;
    top: 0;
    width: 4px;
    left: calc(50vw + 2px);

    .services__line {
      fill: none;
      stroke: $green;
      stroke-width: 10;
      stroke-miterlimit: 10;
    }
  }

  .services__wrapper {
    width: 100%;
    display: table-cell;
    vertical-align: middle;
    position: relative;

    .services__header {
      @media #{$small-only} {
        @include relativeCenteredTop;

        text-align: center;

        .services__title--vertical {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
        }
      }

      @media #{$medium-only} {
        @include absoluteCentered-left;

        padding: 0;
        margin: 0;
        height: 0;
        left: 5.5vw;

        .services__title--vertical {
          -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
        }
      }

      @media #{$large-up} {
        @include absoluteCentered-left;

        padding: 0;
        margin: 0;
        height: 0;
        left: 2.5vw;

        .services__title--vertical {
          -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
        }
      }
    }

    .services__inner {
      position: relative;
      width: 100vw;
      display: inline-flex;

      .services__base {
        position: relative;
        z-index: 1;
        margin: auto;
        display: flex;
        align-items: top;
        justify-content: center;
        width: 100%;
        max-width: 1440px;

        @media #{$small-only} {
          flex-direction: column;
        }

        .services__item {
          display: block;
          float: left;
          width: calc(1440px / 2);

          @media #{$small-only} {
            width: 100vw;
          }

          @media #{$medium-only} {
            width: 45%;
          }

          .services__main {
            @media #{$small-only} {
              padding: 2.5vh 5.5vw 0;
            }

            @media #{$medium-only} {
              width: 100%;
            }

            @media #{$large-up} {
              width: 85%;
            }
          }

          .services__figurewrap {
            position: relative;
            width: 300px;
            margin: auto;

            @media #{$small-only} {
              width: 75%;
            }

            @media #{$medium-only} {
              width: 100%;
            }

            .services__figure-animation {
              position: relative;
              margin: auto;

              .green {
                fill: none;
                stroke: $green;
                stroke-width: 1;
                stroke-linecap: round;
              }
            }

            img,
            svg {
              width: 100%;
              max-width: 100%;
              height: auto;
            }
          }

          .services__figure__caption {
            text-transform: uppercase;
            text-align: center;
            margin-top: 2.5vh;
            font-weight: 700;

            @media #{$small-only} {
              font-size: rem(20px);
            }

            @media #{$medium-only} {
              font-size: rem(24px);
            }

            @media #{$large-up} {
              font-size: rem(26px);
            }
          }

          .service__copy {
            padding: 2.5vh 2vw;
            text-align: center;
          }

          @media #{$medium-only} {
            &:nth-of-type(2) .services__main {
              padding: 0 0 0 10vw;
              float: right;
            }

            &:nth-of-type(3) .services__main {
              padding: 0 5vw;
              float: left;
            }
          }

          @media #{$large-up} {
            &:nth-of-type(2) .services__main {
              float: right;
            }

            &:nth-of-type(3) .services__main {
              float: left;
            }
          }
        }
      }
    }
  }
}

/* SECTION SERVICES */

/* SECTION ADVICE */

.advice {
  background-color: $lightGray;

  .advice__wrapper {
    width: 100%;
    display: table-cell;
    vertical-align: middle;
  }

  .advice__inner {
    position: relative;
    width: 100vw;
    display: inline-flex;
  }

  .advice__backgroundstretch {
    background-image: url('/dist/images/svg/dashed-background-raster.svg');
    background-color: $white;
    background-repeat: repeat;
    background-position: left top;
    width: 33vw;
    height: 100%;
    position: absolute;
    right: 0;
    z-index: 0;
  }

  .advice__base {
    position: relative;
    z-index: 1;
    width: 100%;
    max-width: 1440px;
    margin: auto;
    display: flex;
    justify-content: center;

    @media #{$small-only} {
      width: 100vw;
      flex-direction: column;
    }
  }

  .advice__item {
    position: relative;

    .draw__a__line {
      position: absolute;
      z-index: 2;
      top: 0;
      width: 84px;
      left: -5px;

      .advice__line {
        fill: none;
        stroke: $black;
        stroke-width: 4;
        stroke-miterlimit: 10;
      }
    }

    &:nth-child(1) {
      width: calc(1440px / 4);

      @media #{$small-only} {
        width: 100vw;
        height: 33vh;
      }
    }

    &:nth-child(2) {
      width: calc((1440px / 4) * 3);
      background-color: $white;

      @media #{$small-only} {
        width: 100vw;
      }
    }

    .advice__image {
      z-index: 2;
      height: 618px;
      position: relative;
      top: 96px;

      @media #{$small-only} {
        top: 0;
        height: 100%;
      }

      @media #{$medium-only} {
        min-width: 100%;
        max-width: 431px;
        left: 30px;
      }

      @media #{$large-up} {
        max-width: 100%;
        min-width: 431px;
        left: 0;
      }

      .advice__ratio-content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        z-index: 3;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .advice__image-animation {
        will-change: auto;
        width: 500px;
        height: 100%;
        transform: skew(-6deg);
        background: $advice__image-animation;
        position: absolute;
        left: -34px;
        top: 0;
        transition: all 330ms cubic-bezier(0.345, 0, .5, 1) 0s;

        @media #{$small-only} {
          transform: rotate(90deg) skew(-6deg);
          top: -254px;
          height: 600px;
          left: 0;
        }
      }
    }

    .advice__underlay {
      will-change: auto;
      height: 190px;
      transition: all 330ms;
      transition-timing-function: cubic-bezier(0.56, 1.19, .2, 1.05);
      background-color: $black;
      position: absolute;
      z-index: 0;
      bottom: calc(0px - 113px);

      @media #{$medium-only} {
        max-width: 379px;
        min-width: 100%;
        left: calc(0px - 30px);
      }

      @media #{$large-up} {
        min-width: 379px;
        max-width: 100%;
        left: calc(0px - 20px);
      }

      .advice__ratio-animation {
        will-change: auto;
        background-color: $green;
        width: 0%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        z-index: 10;
        transition: all 330ms;
        transition-timing-function: cubic-bezier(0.56, 1.19, .2, 1.05);
      }
    }

    .advice__itemwrapper {
      @media #{$small-only} {
        padding: 5vh 5.5vw;
      }

      @media #{$medium-only} {
        padding: 10vh 10vw;
      }

      @media #{$large-up} {
        padding: 96px 2.5vw 192px 160px;
      }

      .advice__bodywrapper {
        padding: 0;

        @media #{$small-only} {
          padding: 0;
        }
      }

      .advice__header {
        .advice__title--horizontal {
          margin-left: 2.5vw;
        }
      }
    }

    &.is-in-view {
      .advice__underlay {
        .advice__ratio-animation {
          background-color: $green;
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          display: block;
          z-index: 10;
        }
      }

      .advice__image-animation {
        left: -322px;

        @media #{$small-only} {
          top: -464px;
          height: 600px;
          left: 0;
        }

        @media #{$medium-only} {
          left: -432px;
        }
      }
    }
  }

  .advice__grid {
    margin-top: 5vh;
    position: relative;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;

    .advice__grid-item {
      margin-bottom: 5vh;

      @media #{$small-only} {
        flex-direction: column;
        width: 100%;
      }

      @media #{$medium-only} {
        flex-direction: column;
        width: 100%;
      }

      @media #{$large-up} {
        width: 50%;
      }

      @media #{$large-up} {
        &:nth-last-of-type(-n + 2) {
          margin-bottom: 0;
        }
      }
    }

    .advice__item-wrapper {
      display: flex;
      justify-content: start;
      align-items: flex-start;
    }

    .advice__figure {
      position: relative;
      width: 20%;
      min-width: 88px;

      .green {
        fill: none;
        stroke: $green;
        stroke-width: 1;
        stroke-linecap: round;
      }

      img {
        width: 100%;
      }
    }

    .advice__info {
      width: 80%;
      padding-left: 40px;

      p {
        margin: 0;
        font-size: rem(18px);
      }
    }
  }
}

/* SECTION ADVICE */

/* SECTION PROJECTS */

.projects {
  background-color: $white;
  padding: 0;

  .swiper-button-container {
    position: absolute;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;

    @media #{$small-only} {
      left: 0vw;
      top: 33vh;
    }

    @media #{$medium-only} {
      right: 5.5vw;
      top: 25px;
    }

    @media #{$large-up} {
      right: 2.5vw;
      top: 25px;
    }

    .swiper-button-next,
    .swiper-button-prev {
      background-image: none;
      margin: 0;
      position: relative;
      left: auto;
      top: auto;
      bottom: auto;
      right: auto;

      @media #{$small-only} {
        width: 25px;
      }

      @media #{$medium-only} {
        width: 25px;
      }

      @media #{$large-up} {
        width: 50px;
        height: 72px;
      }
    }

    .swiper-pagination-fraction {
      position: relative;
      color: $green;
      bottom: auto;
      left: auto;
      width: auto;

      @media #{$small-only} {
        font-size: 1.8rem;
        line-height: 30px;
        text-align: left;
      }

      @media #{$medium-only} {
        font-size: 2.8rem;
        line-height: 50px;
      }

      @media #{$large-up} {
        font-size: 3.6rem;
        line-height: 100px;
      }

      span {
        display: inline-block;
        text-align: center;

        @media #{$small-only} {
          font-size: 1.8rem;
          line-height: 30px;
          width: 25px;
        }

        @media #{$medium-only} {
          font-size: 2.8rem;
          line-height: 50px;
          width: 25px;
        }

        @media #{$large-up} {
          font-size: 3.6rem;
          line-height: 100px;
          width: 50px;
        }
      }
    }

    .swiper-button-prev {
      &:hover polyline {
        stroke-width: 9;
        transition: all .4s cubic-bezier(0.345, 0, .5, 1) 0s;
      }
    }

    .swiper-button-next {
      &:hover polyline {
        stroke-width: 9;
        transition: all .4s cubic-bezier(0.345, 0, .5, 1) 0s;
      }
    }
  }

  .projects__wrapper {
    width: 100%;
    min-height: 100vh;

    .projects__inner {
      position: relative;

      .projects__filler-background {
        height: 100%;
        width: 25%;
        display: block;

        /**background-image: url('/dist/images/svg/dashed-pattern-01-01.svg');**/
        background: linear-gradient(135deg, $toBlack 0%, $fromBlack 100%);
        position: absolute;
        left: 0px;
      }
    }

    .projects__base {
      position: relative;
      z-index: 1;
      margin: auto;
      display: flex;
      align-items: center;
      width: 100vw;
      max-width: 1440px;
      background-color: $white;

      @media #{$medium-only} {
        width: 100vw;
      }

      @media #{$small-only} {
        width: 100vw;
        flex-direction: column;
      }

      .swiper-wrapper {
        min-height: 100vh;
        height: 100%;
      }

      .swiper-slide {
        height: 100vh;

        @media #{$small-only} {
          height: 100%;
        }

        @media #{$medium-only} {
          display: flex;
          height: 100%;
        }

        @media #{$large-up} {
          display: flex;
          justify-content: center;
        }
      }
    }

    .projects__item {
      @media #{$medium-only} {
        width: calc((100% / 3) * 2);
      }

      @media #{$small-only} {
        width: 100vw;
      }

      @media #{$large-up} {
        width: 50%;
      }
    }

    .projects-bodywrapper {
      @media #{$small-only} {
        padding: 11vh 5.5vw 5.5vh;
      }

      @media #{$medium-only} {
        padding: 15vh 10vw 10vh 5vw;
      }

      @media #{$large-up} {
        padding: 17.5vh 2.5vw 4.5vh 4.5vw;
      }
    }

    .projects__header {
      .projects__title--horizontal {
        margin-left: 2vw;
      }
    }

    .projects__text {
      margin-bottom: 2.5vh;
    }
  }

  .projects__slider--mask {
    background-color: $black;
    -webkit-clip-path: polygon(100% 0%, 100% 15%, 85% 15%, 85% 100%, 0% 100%, 0% 0%);
    clip-path: polygon(100% 0%, 100% 15%, 85% 15%, 85% 100%, 0% 100%, 0% 0%);

    @media #{$small-only} {
      -webkit-clip-path: polygon(100% 100%, 70% 100%, 70% 83%, 0% 83%, 0% 0%, 100% 0%);
      clip-path: polygon(100% 100%, 70% 100%, 70% 83%, 0% 83%, 0% 0%, 100% 0%);
    }

    .projects__ratio-image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;
      object-fit: cover;

      img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }
  }

  #projects__slider-one,
  #projects__slider-two,
  #projects__slider-three {
    will-change: auto;
    width: 50%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    background-color: $black;
    left: 0;
    top: 0;
    bottom: 0;
    position: relative;
    overflow: hidden;
    display: block;

    @media #{$small-only} {
      @include aspect-ratio(16, 9);

      width: 100vw;
      height: 33.33334vh;
    }

    @media #{$medium-only} {
      height: 100vh;
      width: calc(100% / 3);
    }

    @media #{$large-up} {
      height: 100vh;
    }
  }

  .projects__clip__overlay {
    will-change: auto;
    background-color: $green;
    display: block;
    height: 110%;
    width: 50%;
    position: absolute;
    top: -35px;

    /*right: 15px;*/
    opacity: .7;
    transform: rotate(3deg) translate3d(740px, 0, 0);
    transition: all .4s;
    transition-timing-function: cubic-bezier(0.56, 1.19, .2, 1.05);

    @media #{$small-only} {
      height: 100%;
      width: 105vw;
      transform: rotate(3deg) translate3d(-20px, 260px, 0);
    }
  }

  .swiper-slide-active .projects__clip__overlay {
    @media #{$small-only} {
      transform: rotate(3deg) translate3d(0px, 25vh, 0px);
      transition-delay: .6s;
    }

    @media #{$medium-only} {
      transform: rotate(3deg) translate3d(240px, 0px, 0px);
      transition-delay: .8s;
    }

    @media #{$large-up} {
      transform: rotate(3deg) translate3d(720px, 0px, 0px);
      transition-delay: .8s;
    }
  }

  &.is-in-view {
    .pager__arrow {
      opacity: .5;
    }

    .swiper-slide-active .projects__clip__overlay {
      @media #{$medium-only} {
        transform: rotate(3deg) translate3d(140px, 0px, 0px);
        transition-delay: .8s;
      }

      @media #{$large-up} {
        transform: rotate(3deg) translate3d(396px, 0px, 0px);
        transition-delay: .8s;
      }
    }
  }

  #projects__slider-one {
    transition: all .4s;
    transition-timing-function: cubic-bezier(0.345, 0, .5, 1);

    @media #{$small-only} {
      transform: translate3d(0px, -320px, 0px);
    }

    @media #{$medium-only} {
      transform: translate3d(-740px, 0px, 0px);
    }

    @media #{$large-up} {
      transform: translate3d(-740px, 0px, 0px);
    }
  }

  @media #{$small-only} {
    .swiper-slide:nth-of-type(1) .projects__item {
      transform: translate3d(0px, 650px, 0px);
      transition: all .3s;
      transition-timing-function: cubic-bezier(0.345, 0, .5, 1);
    }

    .swiper-slide:nth-of-type(2) .projects__item {
      transform: translate3d(0px, 1300px, 0px);
      transition: all .3s;
      transition-timing-function: cubic-bezier(0.345, 0, .5, 1);
    }

    .swiper-slide:nth-of-type(3) .projects__item {
      transform: translate3d(0px, 1950px, 0px);
      transition: all .3s;
      transition-timing-function: cubic-bezier(0.345, 0, .5, 1);
    }
  }

  #projects__slider-two {
    transition: all .4s;
    transition-timing-function: cubic-bezier(0.345, 0, .5, 1);

    @media #{$small-only} {
      transform: translate3d(0px, -640px, 0px);
    }

    @media #{$medium-only} {
      transform: translate3d(-2200px, 0px, 0px);
    }

    @media #{$large-up} {
      transform: translate3d(-2200px, 0px, 0px);
    }
  }

  #projects__slider-three {
    transition: all .4s;
    transition-timing-function: cubic-bezier(0.345, 0, .5, 1);

    @media #{$small-only} {
      transform: translate3d(0px, -960px, 0px);
    }

    @media #{$medium-only} {
      transform: translate3d(-3670px, 0px, 0px);
    }

    @media #{$large-up} {
      transform: translate3d(-3670px, 0px, 0px);
    }
  }

  .swiper-slide-active #projects__slider-one,
  .swiper-slide-active #projects__slider-two,
  .swiper-slide-active #projects__slider-three {
    transform: translate3d(0px, 0px, 0px);
    transition-delay: .2s;

    @media #{$small-only} {
      transform: translate3d(0px, 0px, 0px);
      transition-delay: .2s;
    }
  }

  @media #{$small-only} {
    .swiper-slide:nth-of-type(1).swiper-slide-active .projects__item,
    .swiper-slide:nth-of-type(2).swiper-slide-active .projects__item,
    .swiper-slide:nth-of-type(3).swiper-slide-active .projects__item {
      transform: translate3d(0px, 0px, 0px);
      transition-timing-function: cubic-bezier(0.345, 0, .5, 1);
      transition-delay: .3s;
    }
  }

  /**
    &.is-in-view {
      #projects__slider-one {
        transform: translate3d(0px, 0px, 0px);
      }
    }
    **/
}

/* SECTION PROJECTS */

/* SECTION MANAGEMENT */

.management {
  background-color: $lightGray;

  .management__wrapper {
    width: 100%;
    display: table-cell;
    vertical-align: middle;
  }

  .management__inner {
    position: relative;
    width: 100vw;
    display: inline-flex;
  }

  .management__backgroundstretch {
    background-image: url('/app/images/svg/dashed-background-raster.svg');
    background-color: $white;
    background-repeat: repeat;
    background-position: left top;
    width: 33vw;
    height: 100%;
    position: absolute;
    left: 0;
    z-index: 0;
  }

  .management__base {
    position: relative;
    z-index: 1;
    width: 100%;
    max-width: 1440px;
    margin: auto;
    display: flex;
    justify-content: center;

    @media #{$small-only} {
      width: 100vw;
      flex-direction: column-reverse;
    }
  }

  .management__item {
    position: relative;

    .draw__a__line {
      position: absolute;
      z-index: 3;
      top: 0;
      width: 84px;
      right: -10px;

      .management__line {
        fill: none;
        stroke: $black;
        stroke-width: 4;
        stroke-miterlimit: 10;
      }
    }

    &:nth-child(1) {
      width: calc((1440px / 4) * 3);
      background-color: $white;

      @media #{$small-only} {
        width: 100vw;
      }
    }

    &:nth-child(2) {
      width: calc(1440px / 4);

      @media #{$small-only} {
        width: 100vw;
        height: 33vh;
      }
    }

    .management__image {
      z-index: 2;
      height: 618px;
      position: absolute;
      top: 96px;

      @media #{$small-only} {
        top: 0;
        height: 100%;
        position: relative;
      }

      @media #{$medium-only} {
        min-width: 100%;
        max-width: 431px;
        right: 30px;
      }

      @media #{$large-up} {
        max-width: 100%;
        min-width: 431px;
        right: 0;
      }

      .management__ratio-content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
        z-index: 3;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .management__image-animation {
        will-change: auto;
        width: 500px;
        height: 100%;
        transform: skew(-6deg);
        background: $management__image-animation;
        position: absolute;

        /*right: -322px;*/
        right: -34px;
        top: 0;
        transition: all 330ms;
        transition-timing-function: cubic-bezier(0.56, 1.19, .2, 1.05);

        @media #{$small-only} {
          transform: rotate(90deg) skew(-6deg);
          top: -254px;
          height: 600px;
          left: 0;
        }
      }
    }

    .management__underlay {
      will-change: auto;
      height: 190px;
      transition: all 330ms;
      transition-timing-function: cubic-bezier(0.56, 1.19, .2, 1.05);
      background-color: $black;
      position: absolute;
      z-index: 0;
      bottom: calc(0px - 113px);

      @media #{$medium-only} {
        max-width: 379px;
        min-width: 100%;
        right: calc(0px - 30px);
      }

      @media #{$large-up} {
        min-width: 379px;
        max-width: 100%;
        right: calc(0px - 20px);
      }

      .management__ratio-animation {
        will-change: auto;
        background-color: $management__ratio-animation;
        width: 0%;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        display: block;
        z-index: 10;
        transition: all 330ms;
        transition-timing-function: cubic-bezier(0.56, 1.19, .2, 1.05);
      }
    }

    .management__itemwrapper {
      @media #{$small-only} {
        padding: 5vh 5.5vw;
      }

      @media #{$medium-only} {
        padding: 10vh 13vw 10vh 5.5vw;
      }

      @media #{$large-up} {
        padding: 96px 160px 96px 2.5vw;
      }

      .management__bodywrapper {
        padding: 0;

        @media #{$small-only} {
          padding: 0;
        }
      }

      .management__header {
        .management__title--horizontal {
          margin-left: 2.5vw;
        }
      }

      .management__text {
        margin-bottom: 5vh;
      }
    }

    &.is-in-view {
      .management__underlay {
        .management__ratio-animation {
          background-color: $management__ratio-animation;
          width: 100%;
          height: 100%;
          position: absolute;
          right: 0;
          top: 0;
          display: block;
          z-index: 10;
        }
      }

      .management__image-animation {
        right: -322px;

        @media #{$small-only} {
          top: -464px;
          height: 600px;
          left: 0;
        }

        @media #{$medium-only} {
          right: -432px;
        }
      }
    }
  }
}

/* SECTION MANAGEMENT */

/* SECTION ILLUSTRATIONS SLIDER */

.impressions {
  height: auto;
  min-height: auto;

  @media #{$small-only} {
    padding: 0 0 50px 0;
  }

  @media #{$medium-only} {
    padding: 0 0 50px 0;
  }

  .impressions__wrapper {
    width: 100%;
    display: table-cell;
    vertical-align: middle;

    .impressions__inner {
      position: relative;
      width: 100vw;
      display: inline-flex;
    }

    .impressions__base {
      width: 100vw;
      margin: auto;

      .impressions__list {
        position: relative;
        opacity: 1;
        transition: all 330ms cubic-bezier(0.345, 0, .5, 1) 0s;
        perspective: 1000;
        backface-visibility: hidden;

        img {
          width: 100%;
        }

        .impressions__slider__mask {
          /*mask: url(#ding);*/
          background-image: url('/dist/images/png/illustration-slider-mask-gray.png');
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          z-index: 10;
          width: 100%;
          height: 100%;
        }

        .swiper-pagination-bullet {
          background-image: none;
          background-color: $green;
          opacity: 1;
          border: solid 1px $white;
          position: relative;
          top: 2px;
          height: 16px;
          width: 16px;

          &.swiper-pagination-bullet-active {
            background: linear-gradient(135deg, $fromBlack 0%, $toBlack 100%);
            border: solid 1px $white;
            position: relative;
            top: 2px;
          }
        }
      }

      .impressions__item {
        @include aspect-ratio(16, 9);
      }
    }
  }
}

/* SECTION ILLUSTRATIONS SLIDER */

/* SECTION PROFILE */

.profile {
  background: linear-gradient(135deg, $fromBlack 0%, $toBlack 100%);
  color: $white;

  .profile__wrapper {
    width: 100%;
    display: table-cell;
    vertical-align: middle;

    @media #{$small-only} {
      padding-top: 5vh;
    }
  }

  .profile__inner {
    position: relative;
    width: 100vw;
    display: inline-flex;
  }

  .profile__graphic {
    @include absoluteCentered-top;

    svg {
      height: 96px;

      @media #{$small-only} {
        height: 35px;
      }
    }

    .green {
      will-change: auto;
      stroke: $green;
      stroke-miterlimit: 10;
      stroke-width: 1px;
      fill: $black;
      transition: all .4s cubic-bezier(0.345, 0, .5, 1) 0s;
    }
  }

  .profile__header {
    color: $white;

    @media #{$small-only} {
      @include relativeCenteredTop;

      text-align: center;

      .profile__title--vertical {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
    }

    @media #{$medium-only} {
      @include absoluteCentered-left;

      padding: 0;
      margin: 0;
      height: 0;
      left: 5.5vw;

      .profile__title--vertical {
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
      }
    }

    @media #{$large-up} {
      @include absoluteCentered-left;

      padding: 0;
      margin: 0;
      height: 0;
      left: 2.5vw;

      .profile__title--vertical {
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        color: $white;
      }
    }
  }

  .profile__base {
    position: relative;
    z-index: 1;
    width: 100%;
    max-width: 1440px;
    margin: auto;
    flex-direction: row-reverse;
    display: flex;
    align-items: center;
    justify-content: center;

    @media #{$small-only} {
      flex-direction: column;
      text-align: center;
    }

    @media #{$medium-only} {
      flex-direction: column;
      text-align: center;
    }
  }

  .profile__item:nth-of-type(2) {
    width: calc(1440px / 4);

    @media #{$small-only} {
      width: 100vw;
    }
  }

  .profile__item:nth-of-type(3) {
    width: calc((1440px / 4) * 2);
    margin-left: calc(1440px / 4);

    @media #{$small-only} {
      width: 100vw;
      margin-left: 0;
    }

    @media #{$medium-only} {
      width: calc(1440px / 4);
      margin-left: 0;
    }

    .profile__itemwrapper {
      padding: 5vh 0;

      @media #{$small-only} {
        padding: 5vh 5.5vw;
      }
    }
  }

  .profile__itemwrapper {
    @media #{$small-only} {
      padding: 5.5vh 5.5vw 2vh;
    }

    @media #{$medium-only} {
      padding: 5.5vh 5.5vw 0;
    }

    @media #{$large-up} {
      padding: 5vh 2.5vw 5vh 5vw;
    }
  }

  .profile__text--center {
    text-align: center;

    .profile__paragraph {
      color: $white;
    }
  }

  .profile__image {
    @include aspect-ratio(1, 1);

    background-color: $black;
    border-radius: 4px;
    margin: 0 auto;
    box-shadow: 0 5px 7px -2px $profile__image;
    overflow: visible;

    @media #{$small-only} {
      width: 50vw;
    }

    @media #{$medium-only} {
      margin: 10vh auto 0;
    }

    .profile__ratio-content {
      border-radius: 4px;
      object-fit: fill;

      img {
        width: 100%;
      }
    }
  }

  .profile__info-person {
    @include absoluteCentered-bottom;

    bottom: 3vh;
    text-transform: uppercase;

    > span {
      color: $green;
      font-size: $header-size / 2;
      border-bottom: solid 1px $green;
    }
  }

  &.is-in-view {
    .profile__graphic .green {
      fill: $green;
    }
  }
}

/* SECTION PROFILE */

/* SECTION CLIENTS */

.clients {
  background-color: $white;

  .clients__wrapper {
    width: 100%;
    display: table-cell;
    vertical-align: middle;
  }

  .clients__base {
    margin: auto;
    width: 100%;
    max-width: 1440px;
  }

  .clients__header--center {
    text-align: center;
  }

  .clients__body {
    .clients__text--center {
      text-align: center;
    }

    @media #{$small-only} {
      padding: 5.5vh 5.5vw;
    }
  }

  .clients__grid {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5vh;
    justify-content: space-between;
    align-items: center;

    @media #{$small-only} {
      padding: 0 5.5vw;
    }

    @media #{$medium-only} {
      padding: 0 5.5vw;
    }

    @media #{$large-up} {
      padding: 0 2.5vw;
    }
  }

  .clients__grid__item {
    border-radius: 4px;

    @media #{$small-only} {
      width: calc(33.33334% - 0vw);
    }

    @media #{$medium-only} {
      width: calc(25% - 0vw);
    }

    @media #{$large-up} {
      width: calc(20% - 0vw);
    }
  }

  .grid__item__wrappper {
    @include aspect-ratio(1, 1);

    .clients__ratio-content {
      z-index: 2;

      img {
        @include absoluteCentered;

        width: 100%;

        @media #{$small-only} {
          width: 100%;
        }
      }
    }
  }
}

/* SECTION CLIENTS */

/* SECTION FOOTER */

section.footer {
  height: auto;
  min-height: auto;
  overflow: hidden;
  padding: 0;

  .footer__base,
  .footer__credits {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    max-width: 1440px;
    margin: auto;
    position: relative;
  }

  .footer__credits {
    position: absolute;
    bottom: 1.512vh;

    .footer__body {
      @media #{$small-only} {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      }

      @media #{$medium-only} {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      }

      @media #{$large-up} {
        padding: 0 2.5vw !important;
      }
    }
  }

  .footer__item {
    position: relative;
    width: 50%;

    .is-white {
      color: $white;
    }

    .alignright {
      text-align: right;
    }

    .footer__body {
      position: relative;
      z-index: 1;

      a {
        border-bottom: solid 1px $black;

        &:hover {
          border-bottom: solid 1px $white;
        }
      }

      .is-white a {
        border-bottom: solid 1px $white;

        &:hover {
          color: $green;
          border-bottom: solid 1px $green;
        }
      }

      @media #{$small-only} {
        padding: 5vh 5.5vw;
      }

      @media #{$large-up} {
        padding: 5vh 2.5vw;
      }

      .footer__text {
        @media #{$small-only} {
          font-size: rem(12px);
        }

        @media #{$medium-only} {
          font-size: rem(18px);
        }

        &.small {
          white-space: nowrap;

          @media #{$small-only} {
            font-size: rem(11px);
          }

          @media #{$medium-only} {
            font-size: rem(14px);
          }

          @media #{$large-up} {
            font-size: rem(16px);
          }
        }

        margin-bottom: .6rem;
      }

      .menu__list {
        float: right;

        .menu__item {
          display: inline-block;

          .menu__icon {
            display: block;
            overflow: hidden;
            cursor: pointer;

            @media #{$small-only} {
              width: 28px;
              height: 28px;
            }

            @media #{$medium-only} {
              width: 48px;
              height: 48px;
            }

            @media #{$large-up} {
              width: 48px;
              height: 48px;
            }

            .white {
              fill: $white;
            }

            &:hover {
              background-color: $green;
            }

            a {
              text-decoration: none;
              border-bottom: 0;
            }
          }
        }
      }
    }

    .footer__footer {
      position: relative;
      float: right;
      z-index: 3;
      clear: right;

      a {
        border-bottom: 1px solid $white;
      }
    }

    &:nth-of-type(2) .footer__footer .footer__text {
      color: $white;
    }

    @media #{$medium-only} {
      &:nth-of-type(1) .footer__body {
        padding: 5vh 0 5vh 5.5vw;
      }

      &:nth-of-type(2) .footer__body {
        padding: 5vh 5vw 5vh 0;
        color: $white;
      }
    }
  }

  .footer__decoration {
    width: calc(50vw - 50px);
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: $black;
  }

  .footer__decoration::after,
  .footer__decoration::before {
    content: '';
    position: absolute;
  }

  .footer__decoration::before {
    left: -100px;
    border-right: 100px solid $black;
    border-top: 326px solid transparent;
  }

  @supports (-ms-ime-align: auto) {
    .footer__decoration::before {
      border-top: 318px solid transparent;
    }
  }

  .footer-item-right {
    color: $white;
  }
}

/* SECTION FOOTER */

/* SECTION PAGENOTFOUND */

.pagenotfound {
  background-color: $black;
  color: $white;

  .pagenotfound__wrapper {
    width: 100%;
    display: table-cell;
    vertical-align: middle;
  }

  .pagenotfound__graphic {
    @include absoluteCentered-top;

    svg {
      height: 128px;

      @media #{$small-only} {
        height: 72px;
      }
    }

    .green {
      will-change: auto;
      stroke: $green;
      stroke-miterlimit: 10;
      stroke-width: 1px;
      fill: $green;
      transition: all .4s cubic-bezier(0.345, 0, .5, 1) 0s;
    }
  }

  .pagenotfound__inner {
    position: relative;
    width: 100vw;
    display: flex;
  }

  .pagenotfound__base {
    position: relative;
    z-index: 1;
    width: 100%;
    max-width: 1440px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;

    @media #{$small-only} {
      flex-direction: column;
    }

    @media #{$medium-only} {
      flex-direction: column;
    }
  }

  .pagenotfound__bodywrapper {
    @media #{$small-only} {
      padding: 0 10vw;
    }

    @media #{$medium-only} {
      padding: 0 10vw;
    }
  }

  .pagenotfound__header {
    h1 {
      color: $white;
    }
  }

  .pagenotfound__body {
    p {
      color: $white;
    }
  }
}
