ul,
ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.list__item {
  margin: 0 0 1.5vh;
  background-image: url('/dist/images/svg/list-item.svg');
  background-position: left center;
  background-repeat: no-repeat;
  opacity: 1;
  transition: opacity 500ms cubic-bezier(0.345, 0, 0.25, 1) 0s;
  perspective: 1000;
  backface-visibility: hidden;
  transition-delay: 0ms;

  @media #{$small-only} {
    padding-left: 7.5vw;
    background-size: 12px 12px;
  }

  @media #{$medium-only} {
    padding-left: 5vw;
    background-size: 12px 12px;
  }

  @media #{$large-up} {
    padding-left: 2.5vw;
    background-size: 16px 16px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:nth-child(1) {
    transition-delay: 0ms;
  }

  &:nth-child(2) {
    transition-delay: 40ms;
  }

  &:nth-child(3) {
    transition-delay: 80ms;
  }

  &:nth-child(4) {
    transition-delay: 120ms;
  }

  &:nth-child(5) {
    transition-delay: 160ms;
  }

  &:nth-child(6) {
    transition-delay: 200ms;
  }

  &:nth-child(7) {
    transition-delay: 240ms;
  }

  &:nth-child(8) {
    transition-delay: 280ms;
  }
}

.is-in-view .list__item {
  opacity: 1;

  &:nth-child(1) {
    transition-delay: 0ms;
  }

  &:nth-child(2) {
    transition-delay: 40ms;
  }

  &:nth-child(3) {
    transition-delay: 80ms;
  }

  &:nth-child(4) {
    transition-delay: 120ms;
  }

  &:nth-child(5) {
    transition-delay: 160ms;
  }

  &:nth-child(6) {
    transition-delay: 200ms;
  }

  &:nth-child(7) {
    transition-delay: 240ms;
  }

  &:nth-child(8) {
    transition-delay: 260ms;
  }

  &:nth-child(9) {
    transition-delay: 300ms;
  }
}
